#menu-main {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0;
	font-family: 'TTRoundsNeue-Bold';
	font-size: 20px;

	@media screen and (max-width: 1199px) {
		flex-wrap: wrap;
	}

	li {
		text-align: center;
		padding-bottom: 16px;
		flex: 0 0 100%;
		max-width: 100%;

		@media screen and (min-width: 1200px) and (max-width: 1649px) {
			flex: 0 0 150px;
			max-width: calc((100vw / 5) - 90px);
		}

		@media screen and (min-width: 1650px) and (max-width: 1779px) {
			flex: 0 0 200px;
			max-width: calc((100vw / 5) - 100px);
		}

		@media screen and (min-width: 1780px) {
			flex: 0 0 200px;
			max-width: 200px;
		}

		@media screen and (max-height: 599px) and (max-width: 1199px) {
			padding-bottom: 8px;
		}

		@media screen and (max-width: 1199px) {
			&[data-menuanchor="glucks"] {
				order: 2;
			}

			&[data-menuanchor="mission"] {
				order: 3;
			}

			&[data-menuanchor="pils"] {
				order: 4;
			}

			&[data-menuanchor="aktionen"] {
				order: 5;
			}

			&[data-menuanchor="socialmedia"] {
				order: 6;
			}

			&[data-menuanchor="bierstellen"] {
				order: 7;
			}

			&:not(.logo-wrapper) {
				transform: translateX(100vw);
				transition: transform ease-in-out 0.7s;

				.open & {
					transform: translateX(0);
				}
			}
		}

		&.logo-wrapper {
			padding-bottom: 0;

			@media screen and (max-width: 1199px) {
				order: 1;
				text-align: left;
				padding-left: 20px;

				svg {
					width: 126px;
					height: 126px;
				}
			}

			@media screen and (max-width: 575px) {
				order: 1;
				text-align: left;
				padding-left: 0;

				svg {
					width: 60px;
					height: 60px;
				}
			}

			@media screen and (max-height: 599px) and (max-width: 1199px) {
				padding-left: 0;

				svg {
					width: 60px;
					height: 60px;
				}
			}

			a {
				padding: 0;

				&::before {
					display: none;
				}
			}
		}

		&.active a {
			&::before {
				@media screen and (min-width: 1200px) {
					width: 100%;
				}
			}

			@media screen and (max-width: 1199px) {
				&:not([href="#hallo"]) {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='300px' height='4px' viewBox='0 0 300 4' style='enable-background:new 0 0 300 4;' xml:space='preserve'%3E%3Cg%3E%3Cline class='st0' x1='0' y1='2' x2='300' y2='2' fill='none' stroke='%2300305b' stroke-width='4' stroke-miterlimit='10' /%3E%3C/g%3E%3C/svg%3E");
					background-position: center;
					background-repeat: no-repeat;
				}
			}

			&.button {
				background-color: $secondary;
				color: $primary;
				text-shadow: none;

				@media screen and (max-width: 1199px) {
					background-color: $primary;
					color: $secondary;
				}

				&::before {
					display: none;
				}
			}
		}

		a {
			position: relative;
			color: $primary;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: -0.05rem;

			@media screen and (min-width: 1200px) {
				color: $secondary;
				padding: 0 8px;
				text-shadow: 0 0 8px $primary;
			}

			&::before {
				position: absolute;
				top: calc(50% - 1px);
				left: 0;
				width: 0;
				//right: calc(100% + 8px);
				background-color: $primary;
				border-radius: 2px;
				content: '';
				height: 4px;
				transition: width ease-in-out .4s;

				.safari & {
					top: calc(50% - 4px);
				}

				@media screen and (min-width: 1200px) {
					background-color: $secondary;
				}
			}

			//.fp-viewing-aktionen & {
			//	&[href="#aktionen"] {
			//		background-color: green;
			//
			//		&::before {
			//			width: 100%;
			//		}
			//	}
			//}

			&:hover {
				position: relative;

				&::before {
					width: 100%;

					.touch & {
						display: none;
					}
				}
			}

			&.button {
				border: 2px solid $secondary;
				border-radius: 4px;
				transition: all ease-in-out .4s;

				@media screen and (max-width: 1199px) {
					border-color: $primary;
					padding-top: 0;
					padding-bottom: 0;
				}

				&:hover {
					background-color: $secondary;
					color: $primary;
					text-shadow: none;

					@media screen and (max-width: 1199px) {
						background-color: $primary;
						color: $secondary;
					}

					&::before {
						display: none;
					}
				}
			}
		}
	}
}


#socila-menu {
	display: none;
	transform: translateX(100vw);
	transition: transform ease-in-out 0.7s;

	.open & {
		transform: translateX(0);
	}

	@media screen and (max-width: 1199px) {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 60px;
		display: block;
		font-family: 'TTRoundsNeue-Bold';
		font-size: 16px;
		text-transform: uppercase;
		color: $primary;

		a {
			color: $primary;
			text-decoration: none;
			min-width: 100px;

			&:hover {
				text-decoration: underline;
			}
		}

		.instagram a {
			text-align: right;
		}

		.facebook a {
			text-align: left;
		}

		.separator {
			position: relative;
		}
	}

	@media screen and (max-height: 599px) {
		bottom: 30px;
	}

	@media screen and (max-height: 349px) {
		display: none;
	}
}
