/* https://blog.kulturbanause.de/2013/02/retina-media-queries/ */
// DPR (Device-Pixel-Ratio) mixins to provide media-querys

// DPR2: Retina
@mixin dpr2 {
	@media
	screen and (-webkit-min-device-pixel-ratio: 2),
	screen and (min-resolution: 192dpi),
	screen and (min-resolution: 2dppx) {
		@content;
	}
}

// DPR 1.5
@mixin dpr1_5 {
	@media
	screen and (-webkit-min-device-pixel-ratio: 1.5),
	screen and (min-resolution: 144dpi),
	screen and (min-resolution: 1.5dppx) {
		@content;
	}
}
