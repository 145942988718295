.age-verification {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1090;
	background-color: $secondary;
	display: block;
	padding: 0 !important;

	@media screen and (max-height: 600px) {
		.section-inner {
			height: 600px;
			padding: 0 15px;
		}
	}

	@media screen and (min-width: 992px){
		left: 54px;
		right: 54px;
		width: calc(100vw - 54px - 54px) !important;
	}

	@media screen and (min-width: 1920px) {
		left: calc(50% - 960px + 54px);
		right: calc(50% - 960px + 54px);
		width: calc(1920px - 54px - 54px) !important;
	}

	&.hide {
		display: none;
	}

	.content-outer-wrapper {
		height: 100vh;
	}

	.fp-tableCell {
		@media screen and (max-height: 600px) {
			.touch & {
				vertical-align: top;
			}
		}
	}

	.main {
		display: flex;
		flex-wrap: wrap;

		> * {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	p {
		color: $primary;
		font-family: 'TTRoundsNeue-Bold';
		font-size: 22px;
		text-transform: uppercase;
		line-height: 1.325;
		margin-left: auto;
		margin-right: auto;
		max-width: 280px;

		@media screen and (max-height: 600px) {
			order: 2;
		}

		@media screen and (max-width: 575px) and (max-height: 650px) {
			font-size: 14px;
		}

		@media screen and (min-width: 576px) {
			max-width: 100%;
		}

		@media screen and (min-width: 768px) {
			font-size: 28px;
		}

		@media screen and (min-width: 992px) {
			font-size: 32px;
		}

		.touch & {
			order: 2;
		}
	}

	.btn,
	.back-link{
		background-color: transparent;
		border: 5px solid $primary;
		border-radius: 50px;
		color: $primary;
		display: inline-block;
		font-family: 'TTRoundsNeue-Bold';
		font-size: 22px;
		margin-top: 1rem;
		margin-bottom: 2rem;

		@media screen and (max-width: 575px) and (max-height: 650px) {
			font-size: 18px;
		}

		@media screen and (min-width: 768px) {
			font-size: 28px;
		}

		@media screen and (min-width: 992px) {
			font-size: 32px;
		}

		&:focus, &:active {
			outline: 0;
			box-shadow: none;
		}
	}

	.too-young {
		.back-link {
			width: 130px;
			height: 35px;

			@media screen and (max-width: 575px) and (max-height: 650px) {

			}

			@media screen and (min-width: 768px) {
				height: 45px;
			}

			@media screen and (min-width: 992px) {
				height: 50px;
			}

			.unknown-os & ,
			.mac-os & ,
			.unix-os & ,
			.linux-os & {
				padding-top: 10px;
			}
		}
	}

	.header {
		padding-top: 50px;
		padding-bottom: 50px;

		@media screen and (max-height: 385px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}

		@media screen and (max-width: 575px) and (max-height: 650px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}

		@media screen and (min-width: 992px) {
			padding-top: 84px;
			padding-bottom: 90px;
		}

		#logo-main {
			min-width: 100px;
			min-height: 100px;
			width: 25vh;
			height: 25vh;
			max-width: 195px;
			max-height: 195px;

			@media screen and (max-width: 575px) and (max-height: 650px) {
				max-width: 150px;
				max-height: 150px;
			}

			@media screen and (min-width: 992px) {
				max-width: 295px;
				max-height: 295px;
			}

			@media screen and (min-width: 1200px) {
				max-width: 395px;
				max-height: 395px;
			}

			.st0 {
				clip-path: url(#clipPathAgeGate);
				fill: $primary;
			}

			.touch.age-gate-focus & {
				min-width: 0;
				min-height: 0;
				width: 0;
				height: 0;
			}

			// Style for iPad Pro
			@media screen and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape){
				.touch.age-gate-focus.safari &,
				.touch.age-gate-focus.chrome & {
					max-width: 100px;
					max-height: 100px;
					width: 100px;
					height: 100px;
				}
			}

				// Style for iOS (iPhone SE)
			@media screen and (device-width: 640px) and (orientation: portrait){
				.touch.age-gate-focus.safari &,
				.touch.age-gate-focus.chrome & {
					max-width: 150px;
					max-height: 150px;
					width: 150px;
					height: 150px;
				}
			}
		}
	}

	.btn-group {
		text-align: center;

		@media screen and (max-height: 600px) {
			order: 1;
		}

		.touch & {
			order: 1;
		}

		.btn-wrapper {
			display: inline-block;
			margin-left: 15px;
			margin-right: 15px;

			.btn {
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 65px;
				height: 65px;
				padding: 3px 5px 7px;

				@media screen and (max-width: 575px) and (max-height: 650px) {
					width: 65px;
					height: 65px;
				}

				@media screen and (min-width: 768px) {
					width: 80px;
					height: 80px;
				}

				@media screen and (min-width: 992px) {
					width: 100px;
					height: 100px;
				}

				.no-touch.unknown-os & ,
				.no-touch.mac-os & ,
				.no-touch.unix-os & ,
				.no-touch.linux-os & {
					padding: 10px 5px 0;
				}

				.touch.unknown-os & ,
				.touch.mac-os & ,
				.touch.unix-os & ,
				.touch.linux-os & {
					padding: 8px 5px 3px;
				}
			}
		}
	}
}
