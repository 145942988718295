// Variables
$primary: #00305b;
$secondary: #eed26a;

// Default Styling
@import "../fonts/MyFontsWebfontsKit/MyFontsWebfontsKit.css";
@import "Library/mixin";
@import "Library/typography";

@import "Library/layout";
@import "Library/marquee";

// Area Styling
@import "Library/Area/header";

// Menu Stying
@import "Library/Menus/main";

// Section Stylings
@import "Library/Section/start";
@import "Library/Section/glucks";
@import "Library/Section/mission";
@import "Library/Section/pils";
@import "Library/Section/aktionen";
@import "Library/Section/socialmedia";
@import "Library/Section/bestellen";
@import "Library/Section/footer-content";
@import "Library/Section/age-verification";
