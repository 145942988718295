.footer-content {
	position: fixed;
	top: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1050;
	background: $primary;
	padding: 0 !important;
	opacity: 0;
	transition: all ease-in-out .7s;
	user-select: text;

	@media screen and (min-width: 992px) {
		left: 54px;
		right: 54px;
		width: calc(100% - 54px - 54px) !important;
	}

	&.show {
		top: 0;
		opacity: 1;
	}

	.content-outer-wrapper {
		height: 100vh;
	}

	.content-wrapper {
		position: relative;
		border-top: 3px solid $secondary;
		padding: 50px 15px 90px;

		@media screen and (max-height: 569px) {
			height: auto;
			padding: 15px;
		}

		@media screen and (max-width: 1199px), screen and (max-height: 750px) {
			padding: 15px;
			font-size: 16px;
		}

		.content-inner-table {
			display: table;
			width: 100%;

			.content-inner {
				display: table-cell;
				vertical-align: middle;
				height: 100vh;
			}
		}

		.content-inner {
			margin: 0 auto;
			max-width: 960px;
			min-height: calc(100vh - 15px - 15px - 3px);

			@media screen and (min-width: 1200px) {
				min-height: calc(100vh - 50px - 90px - 3px);
			}

			@media screen and (max-width: 575px) {
				padding-bottom: 3rem;
			}

			// More padding at bottom to prevent overlaying toolbar
			.touch.safari & {
				padding-bottom: 7rem;
			}
		}

		h4 {
			margin-bottom: 0;
		}

		a {
			color: $secondary;
			text-decoration: none;
			word-break: break-word;
			padding: 0;

			&:hover {
				&::before {
					display: none;
				}
			}
		}

		a.back-link {
			background-color: transparent;
			border: 3px solid $secondary;
			border-radius: 50px;
			color: $secondary;
			display: inline-block;
			font-family: 'TTRoundsNeue-Bold';
			margin-top: 1rem;
			margin-bottom: 2rem;
			width: 130px;
			padding: 2px 5px 5px;
			text-decoration: none;

			&:focus, &:active {
				outline: 0;
				box-shadow: none;
			}

			&::before {
				display: none;
			}
		}

		ul {
			list-style: none;

			li {
				&::before {
					border-radius: 50%;
					background-color: $secondary;
					content: '';
					display: inline-block;
					margin: 0 8px 2px 0;
					width: 8px;
					height: 8px;
				}
			}
		}
	}
}
