header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 200px;
	z-index: 1030;
	padding: 45px 30px 0;
	margin: 0 auto;
	width: 100%;
	max-width: 1070px;

	@media screen and (min-width: 992px) and (max-width: 1199px) {
		width: calc(100% - 120px);
		padding: 45px 60px 0;
	}

	@media screen and (min-width: 1650px) {
		width: 100%;
		max-width: 1580px;
	}

	@media screen and (max-width: 1199px) {
		height: 130px;
		overflow: hidden;
		transition: height 0s ease 0.7s;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			background-color: transparent;
			content: '';
			display: block;
			transform: translateX(100vw);
			transition: transform ease-in-out 0.7s, background-color ease-in-out 0.7s;
			width: 100vw;
			height: 100vh;
		}

		&.open {
			height: 100vh;
			overflow: auto;
			transition: height 0s ease 0s;

			&::before {
				transform: translateX(0);
				background-color: $secondary;
			}

			#logo-main .st0 {
				fill: $primary;
			}
		}
	}

	@media screen and (max-width: 575px) {
		padding: 15px 30px 0;
		height: 100px;

		&.open {
			height: 100vh;
		}
	}

	@media screen and (max-width: 575px) and (orientation: portrait) {
		height: 70px;

		&.open {
			height: 100vh;
		}
	}

	@media screen and (min-width: 1200px) {
		padding: 30px 60px 0;
	}

	@media screen and (max-height: 599px) and (max-width: 1199px) {
		padding: 10px 10px 0;
		height: 70px;
	}

	// Background for mobile page-title
	@media screen and (orientation: portrait) and (max-width: 767px){
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			background-color: $primary;
		}
	}

	.page-title {
		position: absolute;
		top: 33px;
		left: 100px;
		right: 130px;
		z-index: 1;
		font-family: 'TTRoundsNeue-Bold';
		font-size: 20px;
		text-align: center;
		text-transform: uppercase;

		@media screen and (min-width: 576px) and (orientation: portrait){
			top: 75px;
			font-size: 30px;
		}

		@media screen and (min-width: 768px) {
			display: none;
		}
	}

	#logo-main {
		enable-background: new 0 0 384.38 384.38;
		width: 166px;
		height: 166px;

		@media screen and (min-width: 1200px) and (max-width: 1649px) {
			width: 150px;
			height: 150px;
		}

		.st0 {
			clip-path: url(#clipPathMainMenuLogo);
			fill: $secondary;
			transition: fill ease-in-out 0.7s;
		}
	}

	.navbar-toggler {
		position: absolute;
		top: 80px;
		right: 80px;
		width: 44px;
		height: 32px;
		transform: rotate(0deg);
		transition: .5s ease-in-out;

		@media screen and (min-width: 992px) and (max-width: 1199px) {
			margin-right: 30px;
		}

		@media screen and (max-width: 575px) {
			top: 30px;
		}

		@media screen and (max-height: 599px) and (max-width: 1199px) {
			top: 30px;
			right: 40px;
		}

		span {
			display: block;
			position: absolute;
			height: 7px;
			width: 100%;
			background: $secondary;
			border-radius: 4px;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;

			@media screen and (max-height: 599px) and (max-width: 1199px) {
				height: 5px;
			}

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 13px;
			}

			&:nth-child(4) {
				top: 26px;
			}
		}

		&.open span {
			background-color: $primary;

			&:nth-child(1) {
				top: 13px;
				width: 0;
				left: 50%;
			}

			&:nth-child(2) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
			}

			&:nth-child(4) {
				top: 13px;
				width: 0;
				left: 50%;
			}
		}
	}
}
