@import "../fonts/MyFontsWebfontsKit/MyFontsWebfontsKit.css";
/* https://blog.kulturbanause.de/2013/02/retina-media-queries/ */
body {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #eed26a;
  font-family: "TTRoundsNeue-Black";
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: -0.05rem;
  text-transform: uppercase;
  margin: 0 0 20px;
}
@media screen and (min-width: 576px) {
  h1 {
    font-size: 50px;
  }
}
@media screen and (min-width: 992px) {
  h1 {
    font-size: 60px;
  }
}

h2 {
  color: #eed26a;
  font-family: "TTRoundsNeue-Black";
  font-size: 30px;
  line-height: 1.139;
  text-transform: uppercase;
  margin: 0 0 38px;
  letter-spacing: -0.05rem;
}
@media screen and (min-width: 992px) {
  h2 {
    font-size: 40px;
    margin: 0 0 48px;
  }
}
@media screen and (min-width: 1500px) {
  h2 {
    font-size: 50px;
    margin: 0 0 58px;
  }
}

h3 {
  font-family: "TTRoundsNeue-Black";
  font-size: 22px;
  line-height: 1.139;
  letter-spacing: -0.05rem;
  text-transform: uppercase;
  margin: 0;
}
@media screen and (min-width: 992px) {
  h3 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1500px) {
  h3 {
    font-size: 40px;
  }
}

b, strong {
  font-family: "TTRoundsNeue-Bold";
  font-weight: normal;
}

p {
  margin: 0 0 25px;
}
@media screen and (min-width: 768px) {
  p {
    margin: 0 0 30px;
  }
}
@media screen and (min-width: 1200px) {
  p {
    margin: 0 0 41px;
  }
}

a:focus, a:active {
  outline: none;
}

html body > div[style="z-index:9999999 !important;position:fixed !important;top:20px !important;bottom:auto !important;left:20px !important;right:auto !important;background:red !important;padding:7px 15px !important;font-size:14px !important;font-family:arial !important;color:#fff !important;display:inline-block !important;transform:translate3d(0,0,0) !important;opacity:1 !important;height:auto !important;width:auto !important;zoom:1 !important;margin:auto !important;border:none !important;visibility:visible !important;clip-path:none !important;"] {
  display: none !important;
  max-width: 0 !important;
  overflow: hidden !important;
}

body {
  background-color: #00305b;
  color: #eed26a;
  font-family: "TTRoundsNeue-Regular";
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  cursor: auto;
  cursor: url("../images/cursor.svg") 10 10, auto;
  user-select: none;
}
.edge body, .ie body {
  cursor: auto;
  cursor: url("../images/cursor.cur"), auto;
}
body.rotate-logo-down .logo-wrapper {
  position: relative;
  z-index: 99999;
}
body.rotate-logo-down .logo-wrapper svg {
  transform: rotate(360deg);
  transition: all linear 0.7s;
}
body.rotate-logo-up .logo-wrapper {
  position: relative;
  z-index: 99999;
}
body.rotate-logo-up .logo-wrapper svg {
  transform: rotate(-360deg);
  transition: all linear 0.7s;
}

a, button, select, input, textarea, .btn,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev,
label, .back-link, .link-wrapper, .imprint, .close {
  cursor: auto;
  cursor: url("../images/cursor-pointer.svg") 10 10, auto;
}
.edge a, .ie a, .edge button, .ie button, .edge select, .ie select, .edge input, .ie input, .edge textarea, .ie textarea, .edge .btn, .ie .btn,
.edge .owl-carousel .owl-nav .owl-next,
.ie .owl-carousel .owl-nav .owl-next,
.edge .owl-carousel .owl-nav .owl-prev,
.ie .owl-carousel .owl-nav .owl-prev,
.edge label,
.ie label, .edge .back-link, .ie .back-link, .edge .link-wrapper, .ie .link-wrapper, .edge .imprint, .ie .imprint, .edge .close, .ie .close {
  cursor: auto;
  cursor: url("../images/cursor-pointer.cur"), auto;
}

#fullpage {
  padding: 0;
}
@media screen and (min-width: 992px) {
  #fullpage {
    padding: 0 54px;
  }
}
@media screen and (min-width: 1920px) {
  #fullpage {
    max-width: 1920px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 575px), screen and (max-height: 599px) and (max-width: 1199px) {
  .section {
    padding-top: 100px !important;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .row {
    flex-wrap: nowrap;
  }
}
.row .column {
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .row .column {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .row .column.cell-1of3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.glucks-face {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='39.55px' height='39.55px' viewBox='0 0 39.55 39.55' style='enable-background:new 0 0 39.55 39.55;' xml:space='preserve'%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='39.55' height='39.55'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Ccircle style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;' cx='19.77' cy='19.78' r='18.14'/%3E%3Cpath style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;stroke-linecap:round;' d='M16.43,15.37 c0-1.57-1.27-2.84-2.84-2.84s-2.84,1.27-2.84,2.84'/%3E%3Cpath style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;stroke-linecap:round;' d='M28.79,15.37 c0-1.57-1.27-2.84-2.84-2.84s-2.84,1.27-2.84,2.84'/%3E%3Cpath style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;stroke-linecap:round;' d='M10.78,20.59 c0,4.79,4.03,8.68,8.99,8.68c4.97,0,8.99-3.89,8.99-8.68'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px auto 0;
  width: 55px;
  height: 55px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

a {
  position: relative;
  padding: 8px;
}
a::before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #eed26a;
  border-radius: 2px;
  content: "";
  width: 0;
  height: 4px;
  transition: all ease-in-out 0.4s;
}
.safari a::before {
  top: calc(50% - 4px);
}
a:hover::before {
  width: 100%;
  z-index: 1;
}
.touch a:hover::before {
  display: none;
}

.link-wrapper {
  position: relative;
  display: inline-block;
}

.d-none {
  display: none;
}

@media screen and (min-width: 992px) {
  .d-md-none {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }
}
.marquee {
  display: none;
}
@media screen and (min-width: 992px) {
  .marquee {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1050;
    background: #00305b;
    display: block;
    overflow: hidden;
    width: 54px;
    color: #eed26a;
    font-family: "TTRoundsNeue-Bold";
    line-height: 30px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: -0.05em;
  }
  .age-verified .marquee {
    background: #eed26a;
    color: #00305b;
  }
  .marquee .marquee-inner {
    overflow: hidden;
    white-space: nowrap;
    transform-origin: 0 100%;
    width: 100vh;
  }
  .marquee .text {
    width: 100vh;
  }
  .marquee a {
    color: #eed26a;
    text-decoration: none;
    padding: 0;
  }
  .age-verified .marquee a {
    color: #00305b;
  }
  .marquee a:hover {
    text-decoration: underline;
  }
  .marquee a:before {
    display: none;
  }
  .marquee.left {
    left: 0;
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .marquee.left {
    left: calc(50% - 960px);
  }
}
@media screen and (min-width: 992px) {
  .marquee.left .marquee-inner {
    margin-top: calc(100vh - 30px);
    margin-left: 41px;
    transform: rotate(-90deg);
  }
  .marquee.right {
    right: 0;
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .marquee.right {
    right: calc(50% - 960px);
  }
}
@media screen and (min-width: 992px) {
  .marquee.right .marquee-inner {
    margin-top: -30px;
    margin-left: 13px;
    transform: rotate(90deg);
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  z-index: 1030;
  padding: 45px 30px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1070px;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  header {
    width: calc(100% - 120px);
    padding: 45px 60px 0;
  }
}
@media screen and (min-width: 1650px) {
  header {
    width: 100%;
    max-width: 1580px;
  }
}
@media screen and (max-width: 1199px) {
  header {
    height: 130px;
    overflow: hidden;
    transition: height 0s ease 0.7s;
  }
  header::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    content: "";
    display: block;
    transform: translateX(100vw);
    transition: transform ease-in-out 0.7s, background-color ease-in-out 0.7s;
    width: 100vw;
    height: 100vh;
  }
  header.open {
    height: 100vh;
    overflow: auto;
    transition: height 0s ease 0s;
  }
  header.open::before {
    transform: translateX(0);
    background-color: #eed26a;
  }
  header.open #logo-main .st0 {
    fill: #00305b;
  }
}
@media screen and (max-width: 575px) {
  header {
    padding: 15px 30px 0;
    height: 100px;
  }
  header.open {
    height: 100vh;
  }
}
@media screen and (max-width: 575px) and (orientation: portrait) {
  header {
    height: 70px;
  }
  header.open {
    height: 100vh;
  }
}
@media screen and (min-width: 1200px) {
  header {
    padding: 30px 60px 0;
  }
}
@media screen and (max-height: 599px) and (max-width: 1199px) {
  header {
    padding: 10px 10px 0;
    height: 70px;
  }
}
@media screen and (orientation: portrait) and (max-width: 767px) {
  header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #00305b;
  }
}
header .page-title {
  position: absolute;
  top: 33px;
  left: 100px;
  right: 130px;
  z-index: 1;
  font-family: "TTRoundsNeue-Bold";
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (min-width: 576px) and (orientation: portrait) {
  header .page-title {
    top: 75px;
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) {
  header .page-title {
    display: none;
  }
}
header #logo-main {
  enable-background: new 0 0 384.38 384.38;
  width: 166px;
  height: 166px;
}
@media screen and (min-width: 1200px) and (max-width: 1649px) {
  header #logo-main {
    width: 150px;
    height: 150px;
  }
}
header #logo-main .st0 {
  clip-path: url(#clipPathMainMenuLogo);
  fill: #eed26a;
  transition: fill ease-in-out 0.7s;
}
header .navbar-toggler {
  position: absolute;
  top: 80px;
  right: 80px;
  width: 44px;
  height: 32px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  header .navbar-toggler {
    margin-right: 30px;
  }
}
@media screen and (max-width: 575px) {
  header .navbar-toggler {
    top: 30px;
  }
}
@media screen and (max-height: 599px) and (max-width: 1199px) {
  header .navbar-toggler {
    top: 30px;
    right: 40px;
  }
}
header .navbar-toggler span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #eed26a;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
@media screen and (max-height: 599px) and (max-width: 1199px) {
  header .navbar-toggler span {
    height: 5px;
  }
}
header .navbar-toggler span:nth-child(1) {
  top: 0;
}
header .navbar-toggler span:nth-child(2), header .navbar-toggler span:nth-child(3) {
  top: 13px;
}
header .navbar-toggler span:nth-child(4) {
  top: 26px;
}
header .navbar-toggler.open span {
  background-color: #00305b;
}
header .navbar-toggler.open span:nth-child(1) {
  top: 13px;
  width: 0;
  left: 50%;
}
header .navbar-toggler.open span:nth-child(2) {
  transform: rotate(45deg);
}
header .navbar-toggler.open span:nth-child(3) {
  transform: rotate(-45deg);
}
header .navbar-toggler.open span:nth-child(4) {
  top: 13px;
  width: 0;
  left: 50%;
}

#menu-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "TTRoundsNeue-Bold";
  font-size: 20px;
}
@media screen and (max-width: 1199px) {
  #menu-main {
    flex-wrap: wrap;
  }
}
#menu-main li {
  text-align: center;
  padding-bottom: 16px;
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 1200px) and (max-width: 1649px) {
  #menu-main li {
    flex: 0 0 150px;
    max-width: calc(20vw - 90px);
  }
}
@media screen and (min-width: 1650px) and (max-width: 1779px) {
  #menu-main li {
    flex: 0 0 200px;
    max-width: calc(20vw - 100px);
  }
}
@media screen and (min-width: 1780px) {
  #menu-main li {
    flex: 0 0 200px;
    max-width: 200px;
  }
}
@media screen and (max-height: 599px) and (max-width: 1199px) {
  #menu-main li {
    padding-bottom: 8px;
  }
}
@media screen and (max-width: 1199px) {
  #menu-main li[data-menuanchor=glucks] {
    order: 2;
  }
  #menu-main li[data-menuanchor=mission] {
    order: 3;
  }
  #menu-main li[data-menuanchor=pils] {
    order: 4;
  }
  #menu-main li[data-menuanchor=aktionen] {
    order: 5;
  }
  #menu-main li[data-menuanchor=socialmedia] {
    order: 6;
  }
  #menu-main li[data-menuanchor=bierstellen] {
    order: 7;
  }
  #menu-main li:not(.logo-wrapper) {
    transform: translateX(100vw);
    transition: transform ease-in-out 0.7s;
  }
  .open #menu-main li:not(.logo-wrapper) {
    transform: translateX(0);
  }
}
#menu-main li.logo-wrapper {
  padding-bottom: 0;
}
@media screen and (max-width: 1199px) {
  #menu-main li.logo-wrapper {
    order: 1;
    text-align: left;
    padding-left: 20px;
  }
  #menu-main li.logo-wrapper svg {
    width: 126px;
    height: 126px;
  }
}
@media screen and (max-width: 575px) {
  #menu-main li.logo-wrapper {
    order: 1;
    text-align: left;
    padding-left: 0;
  }
  #menu-main li.logo-wrapper svg {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-height: 599px) and (max-width: 1199px) {
  #menu-main li.logo-wrapper {
    padding-left: 0;
  }
  #menu-main li.logo-wrapper svg {
    width: 60px;
    height: 60px;
  }
}
#menu-main li.logo-wrapper a {
  padding: 0;
}
#menu-main li.logo-wrapper a::before {
  display: none;
}
@media screen and (min-width: 1200px) {
  #menu-main li.active a::before {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  #menu-main li.active a:not([href="#hallo"]) {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='300px' height='4px' viewBox='0 0 300 4' style='enable-background:new 0 0 300 4;' xml:space='preserve'%3E%3Cg%3E%3Cline class='st0' x1='0' y1='2' x2='300' y2='2' fill='none' stroke='%2300305b' stroke-width='4' stroke-miterlimit='10' /%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
  }
}
#menu-main li.active a.button {
  background-color: #eed26a;
  color: #00305b;
  text-shadow: none;
}
@media screen and (max-width: 1199px) {
  #menu-main li.active a.button {
    background-color: #00305b;
    color: #eed26a;
  }
}
#menu-main li.active a.button::before {
  display: none;
}
#menu-main li a {
  position: relative;
  color: #00305b;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: -0.05rem;
}
@media screen and (min-width: 1200px) {
  #menu-main li a {
    color: #eed26a;
    padding: 0 8px;
    text-shadow: 0 0 8px #00305b;
  }
}
#menu-main li a::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 0;
  background-color: #00305b;
  border-radius: 2px;
  content: "";
  height: 4px;
  transition: width ease-in-out 0.4s;
}
.safari #menu-main li a::before {
  top: calc(50% - 4px);
}
@media screen and (min-width: 1200px) {
  #menu-main li a::before {
    background-color: #eed26a;
  }
}
#menu-main li a:hover {
  position: relative;
}
#menu-main li a:hover::before {
  width: 100%;
}
.touch #menu-main li a:hover::before {
  display: none;
}
#menu-main li a.button {
  border: 2px solid #eed26a;
  border-radius: 4px;
  transition: all ease-in-out 0.4s;
}
@media screen and (max-width: 1199px) {
  #menu-main li a.button {
    border-color: #00305b;
    padding-top: 0;
    padding-bottom: 0;
  }
}
#menu-main li a.button:hover {
  background-color: #eed26a;
  color: #00305b;
  text-shadow: none;
}
@media screen and (max-width: 1199px) {
  #menu-main li a.button:hover {
    background-color: #00305b;
    color: #eed26a;
  }
}
#menu-main li a.button:hover::before {
  display: none;
}

#socila-menu {
  display: none;
  transform: translateX(100vw);
  transition: transform ease-in-out 0.7s;
}
.open #socila-menu {
  transform: translateX(0);
}
@media screen and (max-width: 1199px) {
  #socila-menu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    display: block;
    font-family: "TTRoundsNeue-Bold";
    font-size: 16px;
    text-transform: uppercase;
    color: #00305b;
  }
  #socila-menu a {
    color: #00305b;
    text-decoration: none;
    min-width: 100px;
  }
  #socila-menu a:hover {
    text-decoration: underline;
  }
  #socila-menu .instagram a {
    text-align: right;
  }
  #socila-menu .facebook a {
    text-align: left;
  }
  #socila-menu .separator {
    position: relative;
  }
}
@media screen and (max-height: 599px) {
  #socila-menu {
    bottom: 30px;
  }
}
@media screen and (max-height: 349px) {
  #socila-menu {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #section-start .fp-tableCell {
    vertical-align: top;
  }
}
#section-start .section-inner {
  min-height: 250px;
}
@media screen and (min-width: 992px) {
  #section-start .section-inner {
    padding-bottom: 1px;
  }
}
#section-start .section-inner p {
  max-width: 300px;
  margin: 0 auto;
  padding: 0 15px;
}
#section-start .section-inner .column.left {
  display: flex;
  align-items: center;
  justify-content: center;
}
#section-start .section-inner .image {
  background-image: url("../images/start-flasche.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 116vw;
  max-height: 600px;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 1.5dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-1_5x.png");
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-2x.png");
  }
}
@media screen and (min-width: 768px) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-md.png");
    height: calc(100vh - 230px);
    min-height: 500px;
    max-height: none;
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 768px) and (min-resolution: 144dpi), screen and (min-width: 768px) and (min-resolution: 1.5dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-md-1_5x.png");
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-md-2x.png");
  }
}
@media screen and (min-width: 992px) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-lg.png");
  }
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 992px) and (min-resolution: 144dpi), screen and (min-width: 992px) and (min-resolution: 1.5dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-lg-1_5x.png");
  }
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 992px) and (min-resolution: 192dpi), screen and (min-width: 992px) and (min-resolution: 2dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-lg-2x.png");
  }
}
@media screen and (min-width: 1200px) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-xl.png");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1200px) and (min-resolution: 144dpi), screen and (min-width: 1200px) and (min-resolution: 1.5dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-xl-1_5x.png");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-xl-2x.png");
  }
}
@media screen and (min-width: 1400px) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-xxl.png");
  }
}
@media screen and (min-width: 1400px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1400px) and (min-resolution: 144dpi), screen and (min-width: 1400px) and (min-resolution: 1.5dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-xxl-1_5x.png");
  }
}
@media screen and (min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1400px) and (min-resolution: 192dpi), screen and (min-width: 1400px) and (min-resolution: 2dppx) {
  #section-start .section-inner .image {
    background-image: url("../images/start-flasche-xxl-2x.png");
  }
}

@media screen and (min-width: 768px) {
  #section-glucks::before {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    content: "";
    background-image: url("../images/glucks-flaschen-background.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 768px) and (min-resolution: 144dpi), screen and (min-width: 768px) and (min-resolution: 1.5dppx) {
  #section-glucks::before {
    background-image: url("../images/glucks-flaschen-background-1_5x.png");
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2dppx) {
  #section-glucks::before {
    background-image: url("../images/glucks-flaschen-background-2x.png");
  }
}
@media screen and (min-width: 1200px) {
  #section-glucks::before {
    background-image: url("../images/glucks-flaschen-xl.png"), url("../images/glucks-flaschen-background.png");
    background-size: auto 40vw, cover;
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1200px) and (min-resolution: 144dpi), screen and (min-width: 1200px) and (min-resolution: 1.5dppx) {
  #section-glucks::before {
    background-image: url("../images/glucks-flaschen-xl-1_5x.png"), url("../images/glucks-flaschen-background-1_5x.png");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  #section-glucks::before {
    background-image: url("../images/glucks-flaschen-xl-2x.png"), url("../images/glucks-flaschen-background-2x.png");
  }
}
#section-glucks .section-inner {
  padding-bottom: 90px;
}
#section-glucks .section-inner .row .column.left {
  top: 0;
}
#section-glucks .section-inner .row .column.right {
  top: 0;
}
#section-glucks .section-inner .row .column.left {
  position: relative;
}
#section-glucks .section-inner .row .column.left .body-text {
  padding: 0 15px 50px;
}
@media screen and (min-width: 768px) {
  #section-glucks .section-inner .row .column.left .body-text {
    padding: 200px 30px 50px;
  }
}
@media screen and (min-width: 992px) {
  #section-glucks .section-inner .row .column.left .body-text {
    padding: 200px 60px 50px;
  }
}
@media screen and (min-width: 1200px) {
  #section-glucks .section-inner .row .column.left .body-text {
    padding: 200px 120px 90px 80px;
  }
}
#section-glucks .section-inner .row .column.right {
  position: relative;
  background-image: url("../images/glucks-flaschen-xs.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 114vw;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 1.5dppx) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-xs-1_5x.png");
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-xs-2x.png");
  }
}
@media screen and (min-width: 576px) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-sm.png");
  }
}
@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 576px) and (min-resolution: 144dpi), screen and (min-width: 576px) and (min-resolution: 1.5dppx) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-sm-1_5x.png");
  }
}
@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 576px) and (min-resolution: 192dpi), screen and (min-width: 576px) and (min-resolution: 2dppx) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-sm-2x.png");
  }
}
@media screen and (min-width: 768px) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen.png");
    background-position: center bottom;
    background-size: contain;
    height: auto;
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 768px) and (min-resolution: 144dpi), screen and (min-width: 768px) and (min-resolution: 1.5dppx) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-1_5x.png");
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2dppx) {
  #section-glucks .section-inner .row .column.right {
    background-image: url("../images/glucks-flaschen-2x.png");
  }
}
@media screen and (min-width: 1200px) {
  #section-glucks .section-inner .row .column.right {
    background-image: none;
    height: auto;
  }
}
#section-glucks .fp-scroller .section-inner {
  padding-bottom: 0;
}
@media screen and (min-width: 992px) {
  #section-glucks .fp-scroller .section-inner .row {
    margin-top: 0;
    margin-bottom: 0;
    min-height: auto;
  }
}
@media screen and (min-width: 768px) {
  #section-glucks .fp-scroller .section-inner .row .column.left .body-text {
    padding-top: 0;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  #section-mission::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    content: "";
    background-image: url("../images/mission-flasche-md.png"), url("../images/mission-background-md.jpg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain, cover;
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 768px) and (min-resolution: 144dpi), screen and (min-width: 768px) and (min-resolution: 1.5dppx) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-md-1_5x.png"), url("../images/mission-background-md-1_5x.jpg");
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2dppx) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-md-2x.png"), url("../images/mission-background-md-2x.jpg");
  }
}
@media screen and (min-width: 992px) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-lg.png"), url("../images/mission-background-md.jpg");
  }
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 992px) and (min-resolution: 144dpi), screen and (min-width: 992px) and (min-resolution: 1.5dppx) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-lg-1_5x.png"), url("../images/mission-background-md-1_5x.jpg");
  }
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 992px) and (min-resolution: 192dpi), screen and (min-width: 992px) and (min-resolution: 2dppx) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-lg-2x.png"), url("../images/mission-background-md-2x.jpg");
  }
}
@media screen and (min-width: 1200px) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-xl.png"), url("../images/mission-background-md.jpg");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1200px) and (min-resolution: 144dpi), screen and (min-width: 1200px) and (min-resolution: 1.5dppx) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-xl-1_5x.png"), url("../images/mission-background-md-1_5x.jpg");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  #section-mission::before {
    background-image: url("../images/mission-flasche-xl-2x.png"), url("../images/mission-background-md-2x.jpg");
  }
}
@media screen and (min-width: 992px) {
  #section-mission .section-inner {
    padding-bottom: 0;
  }
}
#section-mission .section-inner .row .column.left {
  opacity: 1;
}
@media screen and (min-width: 992px) {
  #section-mission .section-inner .row {
    align-items: center;
  }
}
#section-mission .section-inner .row .column.right {
  order: 1;
}
@media screen and (min-width: 768px) {
  #section-mission .section-inner .row .column.right {
    order: 2;
  }
}
#section-mission .section-inner .row .column.right .body-text {
  padding: 0 15px 50px;
}
@media screen and (min-width: 768px) {
  #section-mission .section-inner .row .column.right .body-text {
    padding: 50px 15px 50px;
  }
}
@media screen and (min-width: 992px) {
  #section-mission .section-inner .row .column.right .body-text {
    padding: 0 60px;
  }
}
@media screen and (min-width: 1200px) {
  #section-mission .section-inner .row .column.right .body-text {
    padding: 0 80px 0 120px;
  }
}
#section-mission .section-inner .row .column.right .body-text .glucks-face {
  margin-top: 58px;
  margin-bottom: 15px;
}
#section-mission .section-inner .row .column.left {
  background-image: none;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto, cover;
  order: 2;
}
@media screen and (min-width: 576px) {
  #section-mission .section-inner .row .column.left {
    background-image: url("../images/mission-flasche.png"), url("../images/mission-background-sm.jpg");
    height: 450px;
  }
}
@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 576px) and (min-resolution: 144dpi), screen and (min-width: 576px) and (min-resolution: 1.5dppx) {
  #section-mission .section-inner .row .column.left {
    background-image: url("../images/mission-flasche-1_5x.png"), url("../images/mission-background-sm-1_5x.jpg");
    background-size: 424px 450px, cover;
  }
}
@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 576px) and (min-resolution: 192dpi), screen and (min-width: 576px) and (min-resolution: 2dppx) {
  #section-mission .section-inner .row .column.left {
    background-image: url("../images/mission-flasche-2x.png"), url("../images/mission-background-sm-2x.jpg");
  }
}
@media screen and (min-width: 768px) {
  #section-mission .section-inner .row .column.left {
    background-image: none;
    order: 1;
  }
}
#section-mission .section-inner .slider-face.rotate360 {
  animation: rotate360 0.7s linear;
}
#section-mission .section-inner .slider {
  min-height: 155px;
}
@media screen and (min-width: 992px) {
  #section-mission .section-inner .slider {
    height: 350px;
  }
}
#section-mission .section-inner .slider .owl-nav {
  display: flex;
  justify-content: space-between;
  font-family: "TTRoundsNeue-Bold";
  font-size: 25px;
  margin: 24px auto 0;
  max-width: 400px;
}
#section-mission .section-inner .slider .owl-nav button[type=button] {
  padding: 10px !important;
  outline: 0;
}
#section-mission .section-inner .slider .owl-nav button[type=button]:focus {
  outline: 0;
}
#section-mission .section-inner .slider .owl-dots {
  counter-reset: slides-num; /* Initialize counter. */
  position: relative;
  bottom: 41px;
  left: calc(50% - 45px);
  font-family: "TTRoundsNeue-Bold";
  font-size: 25px;
  width: 100px;
}
#section-mission .section-inner .slider .owl-dots:after {
  content: counter(slides-num); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
  display: inline-block;
  padding-left: 29px;
  width: 50px;
}
#section-mission .section-inner .slider .owl-dots .owl-dot {
  display: inline-block;
  counter-increment: slides-num; /* Increment counter */
  margin-right: 5px;
}
#section-mission .section-inner .slider .owl-dots .owl-dot span {
  display: none;
}
#section-mission .section-inner .slider .owl-dots .owl-dot.active:before {
  content: counter(slides-num) " /"; /* Use the same counter to get current item. */
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  text-align: right;
}
#section-mission .fp-scroller .section-inner {
  padding-bottom: 0;
}
@media screen and (min-width: 992px) {
  #section-mission .fp-scroller .section-inner .row {
    margin-top: 0;
    margin-bottom: 0;
    min-height: auto;
  }
}
@media screen and (min-width: 768px) {
  #section-mission .fp-scroller .section-inner .row .column.right .body-text {
    padding-top: 0;
    padding-bottom: 50px;
  }
}

@keyframes rotate360 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#section-pils .section-inner.active .row .column.left {
  opacity: 1;
}
#section-pils .section-inner h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 28px;
}
#section-pils .section-inner .image {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;
  margin-top: 1rem;
  width: 100%;
  height: 300px;
}
#section-pils .section-inner .image.sorte-pils {
  background-image: url("../images/glucks-sorten-pils.png");
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 1.5dppx) {
  #section-pils .section-inner .image.sorte-pils {
    background-image: url("../images/glucks-sorten-pils-1_5x.png");
    background-size: 519px 300px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  #section-pils .section-inner .image.sorte-pils {
    background-image: url("../images/glucks-sorten-pils-2x.png");
  }
}
#section-pils .section-inner .image.sorte-helles {
  background-image: url("../images/glucks-sorten-helles.png");
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 1.5dppx) {
  #section-pils .section-inner .image.sorte-helles {
    background-image: url("../images/glucks-sorten-helles-1_5x.png");
    background-size: 520px 300px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  #section-pils .section-inner .image.sorte-helles {
    background-image: url("../images/glucks-sorten-helles-2x.png");
  }
}
#section-pils .section-inner .image.sorte-radler {
  background-image: url("../images/glucks-sorten-radler.png");
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 1.5dppx) {
  #section-pils .section-inner .image.sorte-radler {
    background-image: url("../images/glucks-sorten-radler-1_5x.png");
    background-size: 520px 300px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  #section-pils .section-inner .image.sorte-radler {
    background-image: url("../images/glucks-sorten-radler-2x.png");
  }
}
@media screen and (min-width: 992px) {
  #section-pils .section-inner .row {
    align-items: center;
  }
}
#section-pils .section-inner .row .column .body-text {
  padding: 0 20px 20px;
}

#section-aktionen {
  text-shadow: 0 0 14px #00305b;
}
@media screen and (min-width: 768px) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-md.png"), url("../images/aktionen-background-md.jpg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto;
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 768px) and (min-resolution: 144dpi), screen and (min-width: 768px) and (min-resolution: 1.5dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-md-1_5x.png"), url("../images/aktionen-background-md-1_5x.jpg");
    background-size: 991px auto, 991px auto;
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-md-2x.png"), url("../images/aktionen-background-md-2x.jpg");
    background-size: 991px auto, 991px auto;
  }
}
@media screen and (min-width: 992px) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-lg.png"), url("../images/aktionen-background-lg.jpg");
  }
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 992px) and (min-resolution: 144dpi), screen and (min-width: 992px) and (min-resolution: 1.5dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-lg-1_5x.png"), url("../images/aktionen-background-lg-1_5x.jpg");
    background-size: 1000px auto, 1199px auto;
  }
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 992px) and (min-resolution: 192dpi), screen and (min-width: 992px) and (min-resolution: 2dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-lg-2x.png"), url("../images/aktionen-background-lg-2x.jpg");
    background-size: 1000px auto, 1199px auto;
  }
}
@media screen and (min-width: 1200px) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-lg.png"), url("../images/aktionen-background-xl.jpg");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1200px) and (min-resolution: 144dpi), screen and (min-width: 1200px) and (min-resolution: 1.5dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-lg-1_5x.png"), url("../images/aktionen-background-xl-1_5x.jpg");
  }
}
@media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-lg-2x.png"), url("../images/aktionen-background-xl-2x.jpg");
  }
}
@media screen and (min-width: 1200px) and (max-height: 800px) {
  #section-aktionen {
    background-size: auto 450px, 100% auto;
  }
}
@media screen and (min-width: 1400px) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-xxl.png"), url("../images/aktionen-background-xl.jpg");
    background-size: auto;
  }
}
@media screen and (min-width: 1400px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1400px) and (min-resolution: 144dpi), screen and (min-width: 1400px) and (min-resolution: 1.5dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-xxl-1_5x.png"), url("../images/aktionen-background-xl-1_5x.jpg");
    background-size: contain, 1920px auto;
  }
}
@media screen and (min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1400px) and (min-resolution: 192dpi), screen and (min-width: 1400px) and (min-resolution: 2dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-xxl-2x.png"), url("../images/aktionen-background-xl-2x.jpg");
    background-size: contain, 1920px auto;
  }
}
@media screen and (min-width: 1400px) and (max-height: 800px) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-xxl.png"), url("../images/aktionen-background-xl.jpg");
    background-size: contain, auto;
    background-position: center top 10px, center bottom;
  }
}
@media screen and (min-width: 1400px) and (max-height: 800px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 1400px) and (max-height: 800px) and (min-resolution: 144dpi), screen and (min-width: 1400px) and (max-height: 800px) and (min-resolution: 1.5dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-xxl-1_5x.png"), url("../images/aktionen-background-xl-1_5x.jpg");
    background-size: contain, 1920px auto;
  }
}
@media screen and (min-width: 1400px) and (max-height: 800px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1400px) and (max-height: 800px) and (min-resolution: 192dpi), screen and (min-width: 1400px) and (max-height: 800px) and (min-resolution: 2dppx) {
  #section-aktionen {
    background-image: url("../images/aktionen-flasche-xxl-2x.png"), url("../images/aktionen-background-xl-2x.jpg");
    background-size: contain, 1920px auto;
  }
}
#section-aktionen .section-inner {
  padding-bottom: 90px;
}
#section-aktionen .section-inner .row b, #section-aktionen .section-inner .row strong {
  letter-spacing: -0.05rem;
}
#section-aktionen .section-inner .row a {
  position: relative;
  display: inline-block;
  color: #eed26a;
  font-family: "TTRoundsNeue-Black";
  font-size: 25px;
  letter-spacing: -0.05rem;
  line-height: 1.139;
  text-transform: uppercase;
  text-decoration: none;
}
@media screen and (max-height: 800px) and (min-width: 768px) {
  #section-aktionen .section-inner .row a {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1510px) {
  #section-aktionen .section-inner .row a {
    font-size: 30px;
  }
}
#section-aktionen .section-inner .row a span.icon {
  font-family: "TTRoundsNeue-Regular";
}
#section-aktionen .section-inner .row a.small {
  font-size: inherit;
}
#section-aktionen .section-inner .row a.small::before {
  top: 80%;
}
@media screen and (min-width: 992px) {
  #section-aktionen .section-inner .row p {
    min-height: 130px;
  }
}
@media screen and (min-height: 900px) {
  #section-aktionen .section-inner .row p {
    min-height: 238px;
  }
}
@media screen and (min-height: 900px) {
  #section-aktionen .section-inner .row p {
    min-height: 238px;
  }
}
#section-aktionen .section-inner .row .column.left .body-text {
  padding: 0 15px 50px;
}
@media screen and (min-width: 768px) {
  #section-aktionen .section-inner .row .column.left .body-text {
    padding: 0 80px 90px 15px;
  }
}
@media screen and (min-width: 1200px) {
  #section-aktionen .section-inner .row .column.left .body-text {
    padding: 90px;
  }
}
@media screen and (min-width: 1400px) {
  #section-aktionen .section-inner .row .column.left .body-text {
    padding: 0 185px 110px 95px;
  }
}
#section-aktionen .section-inner .row .column.right .body-text {
  padding: 0 15px 50px;
}
@media screen and (min-width: 768px) {
  #section-aktionen .section-inner .row .column.right .body-text {
    padding: 0 15px 90px 80px;
  }
}
@media screen and (min-width: 1200px) {
  #section-aktionen .section-inner .row .column.right .body-text {
    padding: 90px;
  }
}
@media screen and (min-width: 1400px) {
  #section-aktionen .section-inner .row .column.right .body-text {
    padding: 0 150px 110px 180px;
  }
}
#section-aktionen .section-inner .row .responsive-image {
  background-image: url("../images/aktionen-xs.jpg");
  background-repeat: no-repeat;
  background-position: center;
  margin: 50px 0;
  flex: 0 0 100%;
  max-width: 100%;
  height: 385px;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 1.5dppx) {
  #section-aktionen .section-inner .row .responsive-image {
    background-image: url("../images/aktionen-xs-1_5x.jpg");
    background-size: 575px auto;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  #section-aktionen .section-inner .row .responsive-image {
    background-image: url("../images/aktionen-xs-2x.jpg");
    background-size: 575px auto;
  }
}
@media screen and (min-width: 576px) {
  #section-aktionen .section-inner .row .responsive-image {
    background-image: url("../images/aktionen-sm.jpg");
    height: 513px;
  }
}
@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-width: 576px) and (min-resolution: 144dpi), screen and (min-width: 576px) and (min-resolution: 1.5dppx) {
  #section-aktionen .section-inner .row .responsive-image {
    background-image: url("../images/aktionen-sm-1_5x.jpg");
    background-size: 767px auto;
  }
}
@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 576px) and (min-resolution: 192dpi), screen and (min-width: 576px) and (min-resolution: 2dppx) {
  #section-aktionen .section-inner .row .responsive-image {
    background-image: url("../images/aktionen-sm-2x.jpg");
    background-size: 767px auto;
  }
}
@media screen and (min-width: 768px) {
  #section-aktionen .section-inner .row .responsive-image {
    display: none;
  }
}
#section-aktionen .fp-scroller .section-inner {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  #section-aktionen .fp-scroller .section-inner .row .column.left .body-text {
    padding-bottom: 50px;
  }
}

#section-socialmedia .section-inner h2 {
  margin-top: 50px;
  margin-bottom: 16px;
}
#section-socialmedia .section-inner a {
  color: #eed26a;
  display: inline-block;
  font-size: 25px;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 8px;
}
@media screen and (min-width: 992px) {
  #section-socialmedia .section-inner a {
    font-size: 31px;
  }
}
#section-socialmedia .section-inner .instagram-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 30px;
  width: calc(100vw - 60px);
  height: calc(100vw - 60px);
  max-width: 1000px;
  max-height: 1000px;
  min-height: 260px;
}
#section-socialmedia .section-inner .instagram-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
@media screen and (min-width: 992px) {
  #section-socialmedia .section-inner .instagram-wrapper {
    width: calc(100vw - 108px - 60px);
    height: calc(100vw - 108px - 60px);
  }
}
#section-socialmedia .section-inner .instagram-wrapper iframe {
  border: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#section-bestellen .section-inner {
  padding-bottom: 120px;
}
#section-bestellen .section-inner h2 {
  margin-bottom: 106px;
}
#section-bestellen .section-inner .link-wrapper a {
  color: #eed26a;
  display: block;
  font-family: "TTRoundsNeue-Black";
  font-size: 25px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.139;
  letter-spacing: -0.05rem;
}
@media screen and (min-width: 576px) {
  #section-bestellen .section-inner .link-wrapper a {
    display: inline-block;
    font-size: 30px;
    padding: 15px;
  }
}
@media screen and (min-width: 1200px) {
  #section-bestellen .section-inner .link-wrapper a {
    display: inline-block;
    font-size: 39px;
    padding: 8px;
  }
}
@media screen and (min-width: 1200px) {
  #section-bestellen .section-inner .link-wrapper a.facebook {
    margin-left: 112px;
    margin-right: 98px;
  }
}
#section-bestellen .section-inner .link-wrapper a span.icon {
  font-family: "TTRoundsNeue-Regular";
}
#section-bestellen .section-inner .footer {
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  z-index: 1050;
  display: flex;
  height: 62px;
  transition: all ease-in-out 1s;
  overflow: hidden;
}
@media screen and (max-height: 569px), screen and (max-width: 575px) {
  #section-bestellen .section-inner .footer {
    margin-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  #section-bestellen .section-inner .footer {
    height: 50px;
  }
}
#section-bestellen .section-inner .footer span.imprint, #section-bestellen .section-inner .footer span.privacy {
  background-color: #eed26a;
  display: block;
  color: #00305b;
  flex: 0 0 50%;
  font-family: "TTRoundsNeue-Black";
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.139;
  letter-spacing: -0.05rem;
  padding: 14px 0 18px;
  transition: all ease-in-out 1s;
}
@media screen and (min-width: 576px) {
  #section-bestellen .section-inner .footer span.imprint, #section-bestellen .section-inner .footer span.privacy {
    font-size: 26px;
  }
}
#section-bestellen .section-inner .footer span.imprint::before, #section-bestellen .section-inner .footer span.privacy::before {
  display: none;
}
#section-bestellen .section-inner .footer span.imprint {
  text-align: right;
}
#section-bestellen .section-inner .footer span.imprint .label {
  padding-right: 1rem;
}
#section-bestellen .section-inner .footer span.privacy {
  text-align: left;
}
#section-bestellen .section-inner .footer span.privacy .label {
  padding-left: 1rem;
}

.footer-content {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background: #00305b;
  padding: 0 !important;
  opacity: 0;
  transition: all ease-in-out 0.7s;
  user-select: text;
}
@media screen and (min-width: 992px) {
  .footer-content {
    left: 54px;
    right: 54px;
    width: calc(100% - 54px - 54px) !important;
  }
}
.footer-content.show {
  top: 0;
  opacity: 1;
}
.footer-content .content-outer-wrapper {
  height: 100vh;
}
.footer-content .content-wrapper {
  position: relative;
  border-top: 3px solid #eed26a;
  padding: 50px 15px 90px;
}
@media screen and (max-height: 569px) {
  .footer-content .content-wrapper {
    height: auto;
    padding: 15px;
  }
}
@media screen and (max-width: 1199px), screen and (max-height: 750px) {
  .footer-content .content-wrapper {
    padding: 15px;
    font-size: 16px;
  }
}
.footer-content .content-wrapper .content-inner-table {
  display: table;
  width: 100%;
}
.footer-content .content-wrapper .content-inner-table .content-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
}
.footer-content .content-wrapper .content-inner {
  margin: 0 auto;
  max-width: 960px;
  min-height: calc(100vh - 15px - 15px - 3px);
}
@media screen and (min-width: 1200px) {
  .footer-content .content-wrapper .content-inner {
    min-height: calc(100vh - 50px - 90px - 3px);
  }
}
@media screen and (max-width: 575px) {
  .footer-content .content-wrapper .content-inner {
    padding-bottom: 3rem;
  }
}
.touch.safari .footer-content .content-wrapper .content-inner {
  padding-bottom: 7rem;
}
.footer-content .content-wrapper h4 {
  margin-bottom: 0;
}
.footer-content .content-wrapper a {
  color: #eed26a;
  text-decoration: none;
  word-break: break-word;
  padding: 0;
}
.footer-content .content-wrapper a:hover::before {
  display: none;
}
.footer-content .content-wrapper a.back-link {
  background-color: transparent;
  border: 3px solid #eed26a;
  border-radius: 50px;
  color: #eed26a;
  display: inline-block;
  font-family: "TTRoundsNeue-Bold";
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 130px;
  padding: 2px 5px 5px;
  text-decoration: none;
}
.footer-content .content-wrapper a.back-link:focus, .footer-content .content-wrapper a.back-link:active {
  outline: 0;
  box-shadow: none;
}
.footer-content .content-wrapper a.back-link::before {
  display: none;
}
.footer-content .content-wrapper ul {
  list-style: none;
}
.footer-content .content-wrapper ul li::before {
  border-radius: 50%;
  background-color: #eed26a;
  content: "";
  display: inline-block;
  margin: 0 8px 2px 0;
  width: 8px;
  height: 8px;
}

.age-verification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1090;
  background-color: #eed26a;
  display: block;
  padding: 0 !important;
}
@media screen and (max-height: 600px) {
  .age-verification .section-inner {
    height: 600px;
    padding: 0 15px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification {
    left: 54px;
    right: 54px;
    width: calc(100vw - 54px - 54px) !important;
  }
}
@media screen and (min-width: 1920px) {
  .age-verification {
    left: calc(50% - 960px + 54px);
    right: calc(50% - 960px + 54px);
    width: 1812px !important;
  }
}
.age-verification.hide {
  display: none;
}
.age-verification .content-outer-wrapper {
  height: 100vh;
}
@media screen and (max-height: 600px) {
  .touch .age-verification .fp-tableCell {
    vertical-align: top;
  }
}
.age-verification .main {
  display: flex;
  flex-wrap: wrap;
}
.age-verification .main > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.age-verification p {
  color: #00305b;
  font-family: "TTRoundsNeue-Bold";
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1.325;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
}
@media screen and (max-height: 600px) {
  .age-verification p {
    order: 2;
  }
}
@media screen and (max-width: 575px) and (max-height: 650px) {
  .age-verification p {
    font-size: 14px;
  }
}
@media screen and (min-width: 576px) {
  .age-verification p {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .age-verification p {
    font-size: 28px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification p {
    font-size: 32px;
  }
}
.touch .age-verification p {
  order: 2;
}
.age-verification .btn,
.age-verification .back-link {
  background-color: transparent;
  border: 5px solid #00305b;
  border-radius: 50px;
  color: #00305b;
  display: inline-block;
  font-family: "TTRoundsNeue-Bold";
  font-size: 22px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 575px) and (max-height: 650px) {
  .age-verification .btn,
  .age-verification .back-link {
    font-size: 18px;
  }
}
@media screen and (min-width: 768px) {
  .age-verification .btn,
  .age-verification .back-link {
    font-size: 28px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification .btn,
  .age-verification .back-link {
    font-size: 32px;
  }
}
.age-verification .btn:focus, .age-verification .btn:active,
.age-verification .back-link:focus,
.age-verification .back-link:active {
  outline: 0;
  box-shadow: none;
}
.age-verification .too-young .back-link {
  width: 130px;
  height: 35px;
}
@media screen and (min-width: 768px) {
  .age-verification .too-young .back-link {
    height: 45px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification .too-young .back-link {
    height: 50px;
  }
}
.unknown-os .age-verification .too-young .back-link, .mac-os .age-verification .too-young .back-link, .unix-os .age-verification .too-young .back-link, .linux-os .age-verification .too-young .back-link {
  padding-top: 10px;
}
.age-verification .header {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (max-height: 385px) {
  .age-verification .header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 575px) and (max-height: 650px) {
  .age-verification .header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification .header {
    padding-top: 84px;
    padding-bottom: 90px;
  }
}
.age-verification .header #logo-main {
  min-width: 100px;
  min-height: 100px;
  width: 25vh;
  height: 25vh;
  max-width: 195px;
  max-height: 195px;
}
@media screen and (max-width: 575px) and (max-height: 650px) {
  .age-verification .header #logo-main {
    max-width: 150px;
    max-height: 150px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification .header #logo-main {
    max-width: 295px;
    max-height: 295px;
  }
}
@media screen and (min-width: 1200px) {
  .age-verification .header #logo-main {
    max-width: 395px;
    max-height: 395px;
  }
}
.age-verification .header #logo-main .st0 {
  clip-path: url(#clipPathAgeGate);
  fill: #00305b;
}
.touch.age-gate-focus .age-verification .header #logo-main {
  min-width: 0;
  min-height: 0;
  width: 0;
  height: 0;
}
@media screen and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .touch.age-gate-focus.safari .age-verification .header #logo-main, .touch.age-gate-focus.chrome .age-verification .header #logo-main {
    max-width: 100px;
    max-height: 100px;
    width: 100px;
    height: 100px;
  }
}
@media screen and (device-width: 640px) and (orientation: portrait) {
  .touch.age-gate-focus.safari .age-verification .header #logo-main, .touch.age-gate-focus.chrome .age-verification .header #logo-main {
    max-width: 150px;
    max-height: 150px;
    width: 150px;
    height: 150px;
  }
}
.age-verification .btn-group {
  text-align: center;
}
@media screen and (max-height: 600px) {
  .age-verification .btn-group {
    order: 1;
  }
}
.touch .age-verification .btn-group {
  order: 1;
}
.age-verification .btn-group .btn-wrapper {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
.age-verification .btn-group .btn-wrapper .btn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  padding: 3px 5px 7px;
}
@media screen and (max-width: 575px) and (max-height: 650px) {
  .age-verification .btn-group .btn-wrapper .btn {
    width: 65px;
    height: 65px;
  }
}
@media screen and (min-width: 768px) {
  .age-verification .btn-group .btn-wrapper .btn {
    width: 80px;
    height: 80px;
  }
}
@media screen and (min-width: 992px) {
  .age-verification .btn-group .btn-wrapper .btn {
    width: 100px;
    height: 100px;
  }
}
.no-touch.unknown-os .age-verification .btn-group .btn-wrapper .btn, .no-touch.mac-os .age-verification .btn-group .btn-wrapper .btn, .no-touch.unix-os .age-verification .btn-group .btn-wrapper .btn, .no-touch.linux-os .age-verification .btn-group .btn-wrapper .btn {
  padding: 10px 5px 0;
}
.touch.unknown-os .age-verification .btn-group .btn-wrapper .btn, .touch.mac-os .age-verification .btn-group .btn-wrapper .btn, .touch.unix-os .age-verification .btn-group .btn-wrapper .btn, .touch.linux-os .age-verification .btn-group .btn-wrapper .btn {
  padding: 8px 5px 3px;
}

/*# sourceMappingURL=import.css.map */
