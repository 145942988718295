.marquee {
	display: none;

	@media screen and (min-width: 992px) {
		position: fixed;
		top: 0;
		bottom: 0;
		z-index: 1050;
		background: $primary;
		display: block;
		overflow: hidden;
		width: 54px;
		color: $secondary;
		font-family: 'TTRoundsNeue-Bold';
		line-height: 30px;
		font-size: 18px;
		text-transform: uppercase;
		letter-spacing: -0.05em;

		.age-verified & {
			background: $secondary;
			color: $primary;
		}

		.marquee-inner {
			overflow: hidden;
			white-space: nowrap;
			transform-origin: 0 100%;
			width: 100vh;
		}

		.text {
			width: 100vh;
		}

		a {
			color: $secondary;
			text-decoration: none;
			padding: 0;

			.age-verified & {
				color: $primary;
			}

			&:hover {
				text-decoration: underline;
			}

			&:before {
				display: none;
			}
		}

		&.left {
			left: 0;

			@media screen and (min-width: 1920px) {
				left: calc(50% - 960px);
			}

			.marquee-inner {
				margin-top: calc(100vh - 30px);
				margin-left: 41px;
				transform: rotate(-90deg);
			}
		}

		&.right {
			right: 0;

			@media screen and (min-width: 1920px) {
				right: calc(50% - 960px);
			}

			.marquee-inner {
				margin-top: -30px;
				margin-left: 13px;
				transform: rotate(90deg);
			}
		}
	}
}
