#section-bestellen {
	.section-inner {
		padding-bottom: 120px;

		h2 {
			margin-bottom: 106px;
		}

		.link-wrapper {
			a {
				color: $secondary;
				display: block;
				font-family: 'TTRoundsNeue-Black';
				font-size: 25px;
				text-transform: uppercase;
				text-decoration: none;
				line-height: 1.139;
				letter-spacing: -0.05rem;

				@media screen and (min-width: 576px) {
					display: inline-block;
					font-size: 30px;
					padding: 15px;
				}

				@media screen and (min-width: 1200px) {
					display: inline-block;
					font-size: 39px;
					padding: 8px;
				}

				&.facebook {
					@media screen and (min-width: 1200px) {
						margin-left: 112px;
						margin-right: 98px;
					}
				}

				span.icon {
					font-family: 'TTRoundsNeue-Regular';
				}
			}
		}

		.footer {
			position: absolute;
			bottom: -3px;
			left: 0;
			right: 0;
			z-index: 1050;
			display: flex;
			height: 62px;
			transition: all ease-in-out 1s;
			overflow: hidden;

			@media screen and (max-height: 569px), screen and (max-width: 575px) {
				margin-top: 30px;
			}

			@media screen and (max-width: 575px) {
				height: 50px;
			}

			// More height to prevent overlaying toolbar at samsung browser
			//.touch.samsung & {
			//	height: 120px;
			//}

			span {
				&.imprint,
				&.privacy {
					background-color: $secondary;
					display: block;
					color: $primary;
					flex: 0 0 50%;
					font-family: 'TTRoundsNeue-Black';
					font-size: 18px;
					text-transform: uppercase;
					text-decoration: none;
					line-height: 1.139;
					letter-spacing: -0.05rem;
					padding: 14px 0 18px;
					transition: all ease-in-out 1s;

					@media screen and (min-width: 576px) {
						font-size: 26px;
					}

					&::before {
						display: none;
					}
				}

				&.imprint {
					text-align: right;

					.label {
						padding-right: 1rem;
					}
				}

				&.privacy {
					text-align: left;

					.label {
						padding-left: 1rem;
					}
				}
			}
		}
	}
}
