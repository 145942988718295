#section-start {
	.fp-tableCell {
		@media screen and (max-width: 767px) {
			vertical-align: top;
		}
	}

	.section-inner {
		min-height: 250px;

		@media screen and (min-width: 992px) {
			padding-bottom: 1px;
		}

		p {
			max-width: 300px;
			margin: 0 auto;
			padding: 0 15px;
		}

		.column.left {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.image {
			background-image: url("../images/start-flasche.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 100%;
			height: 116vw;
			max-height: 600px;

			@include dpr1_5 {
				background-image: url("../images/start-flasche-1_5x.png");
			}

			@include dpr2 {
				background-image: url("../images/start-flasche-2x.png");
			}

			@media screen and (min-width: 768px) {
				background-image: url("../images/start-flasche-md.png");
				height: calc(100vh - 230px);
				min-height: 500px;
				max-height: none;

				@include dpr1_5 {
					background-image: url("../images/start-flasche-md-1_5x.png");
				}

				@include dpr2 {
					background-image: url("../images/start-flasche-md-2x.png");
				}
			}

			@media screen and (min-width: 992px) {
				background-image: url("../images/start-flasche-lg.png");

				@include dpr1_5 {
					background-image: url("../images/start-flasche-lg-1_5x.png");
				}

				@include dpr2 {
					background-image: url("../images/start-flasche-lg-2x.png");
				}
			}

			@media screen and (min-width: 1200px) {
				background-image: url("../images/start-flasche-xl.png");

				@include dpr1_5 {
					background-image: url("../images/start-flasche-xl-1_5x.png");
				}

				@include dpr2 {
					background-image: url("../images/start-flasche-xl-2x.png");
				}
			}

			@media screen and (min-width: 1400px) {
				background-image: url("../images/start-flasche-xxl.png");

				@include dpr1_5 {
					background-image: url("../images/start-flasche-xxl-1_5x.png");
				}

				@include dpr2 {
					background-image: url("../images/start-flasche-xxl-2x.png");
				}
			}
		}
	}
}
