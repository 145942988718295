body {
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	color: $secondary;
	font-family: 'TTRoundsNeue-Black';
	font-size: 40px;
	line-height: 1.1;
	letter-spacing: -0.05rem;
	text-transform: uppercase;
	margin: 0 0 20px;

	@media screen and (min-width: 576px) {
		font-size: 50px;
	}

	@media screen and (min-width: 992px) {
		font-size: 60px;
	}
}

h2 {
	color: $secondary;
	font-family: 'TTRoundsNeue-Black';
	font-size: 30px;
	line-height: 1.139;
	text-transform: uppercase;
	margin: 0 0 38px;
	letter-spacing: -0.05rem;

	@media screen and (min-width: 992px) {
		font-size: 40px;
		margin: 0 0 48px;
	}

	@media screen and (min-width: 1500px) {
		font-size: 50px;
		margin: 0 0 58px;
	}
}

h3 {
	font-family: 'TTRoundsNeue-Black';
	font-size: 22px;
	line-height: 1.139;
	letter-spacing: -0.05rem;
	text-transform: uppercase;
	margin: 0;

	@media screen and (min-width: 992px) {
		font-size: 30px;
	}

	@media screen and (min-width: 1500px) {
		font-size: 40px;
	}
}

b, strong {
	font-family: 'TTRoundsNeue-Bold';
	font-weight: normal;
}

p {
	margin: 0 0 25px;

	@media screen and (min-width: 768px) {
		margin: 0 0 30px;
	}

	@media screen and (min-width: 1200px) {
		margin: 0 0 41px;
	}
}

a {
	&:focus,
	&:active {
		outline: none;
	}
}
