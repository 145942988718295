#section-glucks {
	&::before {
		@media screen and (min-width: 768px) {
			position: absolute;
			top: 0;
			left: 50%;
			right: 0;
			bottom: 0;
			content: '';
			background-image: url("../images/glucks-flaschen-background.png");
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: cover;

			@include dpr1_5 {
				background-image: url("../images/glucks-flaschen-background-1_5x.png");
			}

			@include dpr2 {
				background-image: url("../images/glucks-flaschen-background-2x.png");
			}
		}

		@media screen and (min-width: 1200px) {
			background-image: url("../images/glucks-flaschen-xl.png"), url("../images/glucks-flaschen-background.png");
			background-size: auto 40vw, cover;

			@include dpr1_5 {
				background-image: url("../images/glucks-flaschen-xl-1_5x.png"), url("../images/glucks-flaschen-background-1_5x.png");
			}

			@include dpr2 {
				background-image: url("../images/glucks-flaschen-xl-2x.png"), url("../images/glucks-flaschen-background-2x.png");
			}
		}
	}

	.section-inner {
		padding-bottom: 90px;

		.row .column {
			&.left {
				top: 0;
			}

			&.right {
				top: 0;
			}
		}

		.row {
			.column {
				&.left {
					position: relative;

					.body-text {
						padding: 0 15px 50px;

						@media screen and (min-width: 768px) {
							padding: 200px 30px 50px;
						}

						@media screen and (min-width: 992px) {
							padding: 200px 60px 50px;
						}

						@media screen and (min-width: 1200px) {
							padding: 200px 120px 90px 80px;
						}
					}
				}

				&.right {
					position: relative;
					background-image: url("../images/glucks-flaschen-xs.png");
					background-repeat: no-repeat;
					background-position: center bottom;
					background-size: cover;
					height: 114vw;

					@include dpr1_5 {
						background-image: url("../images/glucks-flaschen-xs-1_5x.png");
					}

					@include dpr2 {
						background-image: url("../images/glucks-flaschen-xs-2x.png");
					}

					@media screen and (min-width: 576px) {
						background-image: url("../images/glucks-flaschen-sm.png");

						@include dpr1_5 {
							background-image: url("../images/glucks-flaschen-sm-1_5x.png");
						}

						@include dpr2 {
							background-image: url("../images/glucks-flaschen-sm-2x.png");
						}
					}

					@media screen and (min-width: 768px) {
						background-image: url("../images/glucks-flaschen.png");
						background-position: center bottom;
						background-size: contain;
						height: auto;

						@include dpr1_5 {
							background-image: url("../images/glucks-flaschen-1_5x.png");
						}

						@include dpr2 {
							background-image: url("../images/glucks-flaschen-2x.png");
						}
					}

					@media screen and (min-width: 1200px) {
						background-image: none;
						height: auto;
					}
				}
			}
		}
	}

	// style for scrolling
	.fp-scroller .section-inner {
		padding-bottom: 0;

		.row {
			@media screen and (min-width: 992px) {
				margin-top: 0;
				margin-bottom: 0;
				min-height: auto;
			}

			.column {
				&.left {
					.body-text {
						@media screen and (min-width: 768px) {
							padding-top: 0;
							padding-bottom: 50px;
						}
					}
				}
			}
		}
	}
}
