// Only for debug / dev
html body > div[style="z-index:9999999 !important;position:fixed !important;top:20px !important;bottom:auto !important;left:20px !important;right:auto !important;background:red !important;padding:7px 15px !important;font-size:14px !important;font-family:arial !important;color:#fff !important;display:inline-block !important;transform:translate3d(0,0,0) !important;opacity:1 !important;height:auto !important;width:auto !important;zoom:1 !important;margin:auto !important;border:none !important;visibility:visible !important;clip-path:none !important;"] {
	display: none !important;
	max-width: 0 !important;
	overflow: hidden !important;
}

body {
	background-color: $primary;
	color: $secondary;
	font-family: 'TTRoundsNeue-Regular';
	font-size: 18px;
	line-height: 1.4;
	text-align: center;
	cursor: auto;
	cursor: url("../images/cursor.svg") 10 10, auto;
	user-select: none;

	.edge &,
	.ie & {
		cursor: auto;
		cursor: url("../images/cursor.cur"), auto;
	}

	&.rotate-logo-down {
		.logo-wrapper {
			// Fix for iOS (iPad Air2) rendering bug
			position: relative;
			z-index: 99999;

			// Animaiton
			svg {
				transform: rotate(360deg);
				transition: all linear .7s;
			}
		}
	}

	&.rotate-logo-up {
		.logo-wrapper {
			// Fix for iOS (iPad Air2) rendering bug
			position: relative;
			z-index: 99999;

			// Animaiton
			svg {
				transform: rotate(-360deg);
				transition: all linear .7s;
			}
		}
	}
}

// Cursor styling
a, button, select, input, textarea, .btn,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev,
label, .back-link, .link-wrapper, .imprint, .close {
	cursor: auto;
	cursor: url("../images/cursor-pointer.svg") 10 10, auto;

	.edge &,
	.ie & {
		cursor: auto;
		cursor: url("../images/cursor-pointer.cur"), auto;
	}
}

#fullpage {
	padding: 0;

	@media screen and (min-width: 992px) {
		padding: 0 54px;
	}

	@media screen and (min-width: 1920px) {
		max-width: 1920px;
		margin: 0 auto;
	}
}

.section {
	@media screen and (max-width: 575px), screen and (max-height: 599px) and (max-width: 1199px)  {
		padding-top: 100px !important;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	height: 100%;

	@media screen and (min-width: 768px) {
		flex-wrap: nowrap;
	}

	.column {
		flex: 0 0 100%;
		max-width: 100%;

		@media screen and (min-width: 768px) {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.cell-1of3 {
			@media screen and (min-width: 768px) {
				flex: 0 0 33.33%;
				max-width: 33.33%;
			}
		}
	}
}

.glucks-face {
	background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='39.55px' height='39.55px' viewBox='0 0 39.55 39.55' style='enable-background:new 0 0 39.55 39.55;' xml:space='preserve'%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='39.55' height='39.55'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Ccircle style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;' cx='19.77' cy='19.78' r='18.14'/%3E%3Cpath style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;stroke-linecap:round;' d='M16.43,15.37 c0-1.57-1.27-2.84-2.84-2.84s-2.84,1.27-2.84,2.84'/%3E%3Cpath style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;stroke-linecap:round;' d='M28.79,15.37 c0-1.57-1.27-2.84-2.84-2.84s-2.84,1.27-2.84,2.84'/%3E%3Cpath style='clip-path:url(%23SVGID_2_);fill:none;stroke:%23ecd05d;stroke-width:3.28;stroke-linecap:round;' d='M10.78,20.59 c0,4.79,4.03,8.68,8.99,8.68c4.97,0,8.99-3.89,8.99-8.68'/%3E%3C/g%3E%3C/svg%3E");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 50px auto 0;
	width: 55px;
	height: 55px;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

a {
	position: relative;
	padding: 8px;

	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: $secondary;
		border-radius: 2px;
		content: '';
		width: 0;
		height: 4px;
		transition: all ease-in-out 0.4s;

		.safari & {
			top: calc(50% - 4px);
		}
	}

	&:hover {
		&::before {
			width: 100%;
			z-index: 1;

			.touch & {
				display: none;
			}
		}
	}
}

// IE Fix for transition
.link-wrapper {
	position: relative;
	display: inline-block;
}

// Display none utilities
.d-none {
	display: none;
}

@media screen and (min-width: 992px) {
	.d-md-none {
		display: none;
	}
}

@media screen and (min-width: 1200px) {
	.d-xl-none {
		display: none;
	}
}

// Display Info on Device for DPR
//@include dpr1_5 {
//	body::before {
//		position: absolute;
//		z-index: 999;
//		left: 0;
//		right: 0;
//		bottom: 0;
//		content: 'DPR 1.5';
//		display: block;
//		background: blue;
//		padding: 1rem;
//	}
//}
//
//
//@include dpr2 {
//	body::before {
//		position: absolute;
//		z-index: 999;
//		left: 0;
//		right: 0;
//		bottom: 0;
//		content: 'DPR 2';
//		display: block;
//		background: blue;
//		padding: 1rem
//	}
//}
