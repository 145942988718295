#section-mission {
	&::before {
		@media screen and (min-width: 768px) {
			position: absolute;
			top: 0;
			left: 0;
			right: 50%;
			bottom: 0;
			content: '';
			background-image: url("../images/mission-flasche-md.png"), url("../images/mission-background-md.jpg");
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: contain, cover;

			@include dpr1_5 {
				background-image: url("../images/mission-flasche-md-1_5x.png"), url("../images/mission-background-md-1_5x.jpg");
			}

			@include dpr2 {
				background-image: url("../images/mission-flasche-md-2x.png"), url("../images/mission-background-md-2x.jpg");
			}
		}

		@media screen and (min-width: 992px) {
			background-image: url("../images/mission-flasche-lg.png"), url("../images/mission-background-md.jpg");

			@include dpr1_5 {
				background-image: url("../images/mission-flasche-lg-1_5x.png"), url("../images/mission-background-md-1_5x.jpg");
			}

			@include dpr2 {
				background-image: url("../images/mission-flasche-lg-2x.png"), url("../images/mission-background-md-2x.jpg");
			}
		}

		@media screen and (min-width: 1200px) {
			background-image: url("../images/mission-flasche-xl.png"), url("../images/mission-background-md.jpg");

			@include dpr1_5 {
				background-image: url("../images/mission-flasche-xl-1_5x.png"), url("../images/mission-background-md-1_5x.jpg");
			}

			@include dpr2 {
				background-image: url("../images/mission-flasche-xl-2x.png"), url("../images/mission-background-md-2x.jpg");
			}
		}
	}

	.section-inner {
		@media screen and (min-width: 992px) {
			padding-bottom: 0;
		}

		.row .column.left {
			opacity: 1;
		}

		.row {
			@media screen and (min-width: 992px) {
				align-items: center;
			}

			.column {
				&.right {
					order: 1;

					@media screen and (min-width: 768px) {
						order: 2;
					}

					.body-text {
						padding: 0 15px 50px;

						@media screen and (min-width: 768px) {
							padding: 50px 15px 50px;
						}

						@media screen and (min-width: 992px) {
							padding: 0 60px;
						}

						@media screen and (min-width: 1200px) {
							padding: 0 80px 0 120px;
						}

						.glucks-face {
							margin-top: 58px;
							margin-bottom: 15px;
						}
					}
				}

				&.left {
					background-image: none;
					background-repeat: no-repeat;
					background-position: center bottom;
					background-size: auto, cover;
					order: 2;

					@media screen and (min-width: 576px) {
						background-image: url("../images/mission-flasche.png"), url("../images/mission-background-sm.jpg");
						height: 450px;

						@include dpr1_5 {
							background-image: url("../images/mission-flasche-1_5x.png"), url("../images/mission-background-sm-1_5x.jpg");
							background-size: 424px 450px, cover;
						}

						@include dpr2 {
							background-image: url("../images/mission-flasche-2x.png"), url("../images/mission-background-sm-2x.jpg");
						}
					}

					@media screen and (min-width: 768px) {
						background-image: none;
						order: 1;
					}
				}
			}
		}

		.slider-face {
			&.rotate360 {
				animation: rotate360 0.7s linear;
			}
		}

		.slider {
			min-height: 155px;

			@media screen and (min-width: 992px) {
				height: 350px;
			}

			.owl-nav {
				display: flex;
				justify-content: space-between;
				font-family: 'TTRoundsNeue-Bold';
				font-size: 25px;
				margin: 24px auto 0;
				max-width: 400px;

				button[type="button"] {
					padding: 10px !important;
					outline: 0;

					&:focus {
						outline: 0;
					}
				}
			}

			.owl-dots {
				counter-reset: slides-num; /* Initialize counter. */
				position: relative;
				bottom: 41px;
				left: calc(50% - 45px);
				font-family: 'TTRoundsNeue-Bold';
				font-size: 25px;
				width: 100px;

				&:after {
					content: counter(slides-num); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
					display: inline-block;
					padding-left: 29px;
					width: 50px;
				}

				.owl-dot {
					display: inline-block;
					counter-increment: slides-num; /* Increment counter */
					margin-right: 5px;

					span {
						display: none;
					}

					&.active {
						&:before {
							content: counter(slides-num) " /"; /* Use the same counter to get current item. */
							display: inline-block;
							vertical-align: middle;
							position: absolute;
							left: 0;
							top: 0;
							width: 50px;
							text-align: right;
						}
					}
				}
			}
		}
	}

	// style for scrolling
	.fp-scroller .section-inner {
		padding-bottom: 0;

		.row {
			@media screen and (min-width: 992px) {
				margin-top: 0;
				margin-bottom: 0;
				min-height: auto;
			}

			.column {
				&.right {
					.body-text {
						@media screen and (min-width: 768px) {
							padding-top: 0;
							padding-bottom: 50px;
						}
					}
				}
			}
		}
	}
}


@keyframes rotate360 {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
