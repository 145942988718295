#section-aktionen {
	text-shadow: 0 0 14px $primary;

	@media screen and (min-width: 768px) {
		background-image: url("../images/aktionen-flasche-md.png"), url("../images/aktionen-background-md.jpg");
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: auto;

		@include dpr1_5 {
			background-image: url("../images/aktionen-flasche-md-1_5x.png"), url("../images/aktionen-background-md-1_5x.jpg");
			background-size: 991px auto, 991px auto;
		}

		@include dpr2 {
			background-image: url("../images/aktionen-flasche-md-2x.png"), url("../images/aktionen-background-md-2x.jpg");
			background-size: 991px auto, 991px auto;
		}
	}

	@media screen and (min-width: 992px) {
		background-image: url("../images/aktionen-flasche-lg.png"), url("../images/aktionen-background-lg.jpg");

		@include dpr1_5 {
			background-image: url("../images/aktionen-flasche-lg-1_5x.png"), url("../images/aktionen-background-lg-1_5x.jpg");
			background-size: 1000px auto, 1199px auto;
		}

		@include dpr2 {
			background-image: url("../images/aktionen-flasche-lg-2x.png"), url("../images/aktionen-background-lg-2x.jpg");
			background-size: 1000px auto, 1199px auto;
		}
	}

	@media screen and (min-width: 1200px) {
		background-image: url("../images/aktionen-flasche-lg.png"), url("../images/aktionen-background-xl.jpg");

		@include dpr1_5 {
			background-image: url("../images/aktionen-flasche-lg-1_5x.png"), url("../images/aktionen-background-xl-1_5x.jpg");
		}

		@include dpr2 {
			background-image: url("../images/aktionen-flasche-lg-2x.png"), url("../images/aktionen-background-xl-2x.jpg");
		}
	}

	//
	@media screen and (min-width: 1200px) and (max-height: 800px) {
		background-size: auto 450px, 100% auto;
	}

	@media screen and (min-width: 1400px) {
		background-image: url("../images/aktionen-flasche-xxl.png"), url("../images/aktionen-background-xl.jpg");
		background-size: auto;

		@include dpr1_5 {
			background-image: url("../images/aktionen-flasche-xxl-1_5x.png"), url("../images/aktionen-background-xl-1_5x.jpg");
			background-size: contain, 1920px auto;
		}

		@include dpr2 {
			background-image: url("../images/aktionen-flasche-xxl-2x.png"), url("../images/aktionen-background-xl-2x.jpg");
			background-size: contain, 1920px auto;
		}
	}

	@media screen and (min-width: 1400px) and (max-height: 800px) {
		background-image: url("../images/aktionen-flasche-xxl.png"), url("../images/aktionen-background-xl.jpg");
		background-size: contain, auto;
		background-position: center top 10px, center bottom;

		@include dpr1_5 {
			background-image: url("../images/aktionen-flasche-xxl-1_5x.png"), url("../images/aktionen-background-xl-1_5x.jpg");
			background-size: contain, 1920px auto;
		}

		@include dpr2 {
			background-image: url("../images/aktionen-flasche-xxl-2x.png"), url("../images/aktionen-background-xl-2x.jpg");
			background-size: contain, 1920px auto;
		}
	}

	.section-inner {
		padding-bottom: 90px;

		.row {
			b, strong {
				letter-spacing: -0.05rem;
			}

			a {
				position: relative;
				display: inline-block;
				color: $secondary;
				font-family: 'TTRoundsNeue-Black';
				font-size: 25px;
				letter-spacing: -0.05rem;
				line-height: 1.139;
				text-transform: uppercase;
				text-decoration: none;

				@media screen and (max-height: 800px) and (min-width: 768px) {
					margin-bottom: 100px;
				}

				@media screen and (min-width: 1510px) {
					font-size: 30px;
				}

				span.icon {
					font-family: 'TTRoundsNeue-Regular';
				}

				&.small {
					font-size: inherit;

					&::before {
						top: 80%;
					}
				}
			}

			p {
				@media screen and (min-width: 992px) {
					min-height: 130px;
				}

				@media screen and (min-height: 900px) {
					min-height: 238px;
				}

				@media screen and (min-height: 900px) {
					min-height: 238px;
				}
			}

			.column {
				&.left {
					.body-text {
						padding: 0 15px 50px;

						@media screen and (min-width: 768px) {
							padding: 0 80px 90px 15px;
						}

						@media screen and (min-width: 1200px) {
							padding: 90px;
						}

						@media screen and (min-width: 1400px) {
							padding: 0 185px 110px 95px;
						}
					}
				}

				&.right {
					.body-text {
						padding: 0 15px 50px;

						@media screen and (min-width: 768px) {
							padding: 0 15px 90px 80px;
						}

						@media screen and (min-width: 1200px) {
							padding: 90px;
						}

						@media screen and (min-width: 1400px) {
							padding: 0 150px 110px 180px;
						}
					}
				}
			}

			.responsive-image {
				background-image: url("../images/aktionen-xs.jpg");
				background-repeat: no-repeat;
				background-position: center;
				margin: 50px 0;
				flex: 0 0 100%;
				max-width: 100%;
				height: 385px;

				@include dpr1_5 {
					background-image: url("../images/aktionen-xs-1_5x.jpg");
					background-size: 575px auto;
				}

				@include dpr2 {
					background-image: url("../images/aktionen-xs-2x.jpg");
					background-size: 575px auto;
				}

				@media screen and (min-width: 576px) {
					background-image: url("../images/aktionen-sm.jpg");
					height: 513px;

					@include dpr1_5 {
						background-image: url("../images/aktionen-sm-1_5x.jpg");
						background-size: 767px auto;
					}

					@include dpr2 {
						background-image: url("../images/aktionen-sm-2x.jpg");
						background-size: 767px auto;
					}
				}

				@media screen and (min-width: 768px) {
					display: none;
				}
			}
		}
	}

	// style for scrolling
	.fp-scroller .section-inner {
		padding-bottom: 0;

		.row {
			.column {
				&.left {
					.body-text {
						@media screen and (min-width: 768px) {
							padding-bottom: 50px;
						}
					}
				}
			}
		}
	}
}
