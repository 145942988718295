#section-socialmedia .section-inner {
	h2 {
		margin-top: 50px;
		margin-bottom: 16px;
	}

	a {
		color: $secondary;
		display: inline-block;
		font-size: 25px;
		text-decoration: none;
		text-transform: uppercase;
		margin: 0 8px;

		@media screen and (min-width: 992px){
			font-size: 31px;
		}
	}

	.instagram-wrapper {
		position: relative;
		margin: 0 auto;
		padding: 0 30px;
		width: calc(100vw - (2 * 30px));
		height: calc(100vw - (2 * 30px));
		max-width: 1000px;
		max-height: 1000px;
		min-height: 260px;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
		}

		@media screen and (min-width: 992px) {
			width: calc(100vw - (2 * 54px) - (2 * 30px));
			height: calc(100vw - (2 * 54px) - (2 * 30px));
		}

		iframe {
			border: none;
			overflow: hidden;
			width: 100%;
			height: 100%;
		}
	}
}

