#section-pils {
	.section-inner {
		&.active {
			.row .column.left {
				opacity: 1;
			}
		}

		h3 {
			font-size: 20px;
			font-weight: 400;
			letter-spacing: -0.8px;
			line-height: 28px;
		}

		.image {
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: auto;
			margin-top: 1rem;
			width: 100%;
			height: 300px;


			&.sorte-pils {
				background-image: url("../images/glucks-sorten-pils.png");

				@include dpr1_5 {
					background-image: url("../images/glucks-sorten-pils-1_5x.png");
					background-size: 519px 300px;
				}

				@include dpr2 {
					background-image: url("../images/glucks-sorten-pils-2x.png");
				}
			}

			&.sorte-helles {
				background-image: url("../images/glucks-sorten-helles.png");

				@include dpr1_5 {
					background-image: url("../images/glucks-sorten-helles-1_5x.png");
					background-size: 520px 300px;
				}

				@include dpr2 {
					background-image: url("../images/glucks-sorten-helles-2x.png");
				}
			}

			&.sorte-radler {
				background-image: url("../images/glucks-sorten-radler.png");

				@include dpr1_5 {
					background-image: url("../images/glucks-sorten-radler-1_5x.png");
					background-size: 520px 300px;
				}

				@include dpr2 {
					background-image: url("../images/glucks-sorten-radler-2x.png");
				}
			}
		}

		.row {
			@media screen and (min-width: 992px) {
				align-items: center;
			}

			.column {
				.body-text {
					padding: 0 20px 20px;
				}
			}
		}
	}
}
